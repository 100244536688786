import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React from 'react';
import { imgPasswordInVisible, imgPasswordVisible, } from "./assets";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { Country, State, City, ICountry, IState, ICity } from 'country-state-city';
import { Languages, getTranslationConfig } from '../../../components/src/helpers';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { CountryCode, getCountryCallingCode, parsePhoneNumber, PhoneNumber } from "libphonenumber-js";


export const lang = Languages.en;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: any;
  password: any;
  otpAuthToken: string;
  reTypePassword: string;
  pwdMsg: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: any;
  onlyPhone:any;
  emailError: boolean;
  spaNameError: boolean;
  spaSummaryError: boolean;
  pwdError: boolean;
  isSuccess: boolean;
  typeError: boolean;
  countryError: boolean;
  cityError: boolean;
  stateError: boolean;
  nameError: boolean;
  open: boolean;
  confirmPasswordError: boolean;
  confirmPwdError: boolean;
  apiMessage: string;
  fullName: string;
  genderType: string;
  country: string;
  city: string;
  stateValue: string
  spaName: string;
  webLink: string;
  spaSummary: string;
  phoneNumberError: boolean;
  confirmPassword: any;
  checked: boolean;
  user_role: string;
  avatarImage: any;
  imageUrl: any;
  addressError: boolean;
  address: string;
  anchorEl: HTMLButtonElement | null,
  upldAvatarLink: string | ArrayBuffer | null,
  countries: ICountry[],
  states: IState[],
  cities: ICity[],
  countryCode: string,
  businessLicenseImage: File[],
  certificateImage: File[],
  otherImages: File[][],
  uploadersNumber: number,
  addDisabled: boolean,
  businessLicenseError: string,
  certificateError: string,
  fileError: string
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  createSpaAccountApiCallId: any;
  validationApiCallId: string = "";
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  textHeading: string = "";
  formTiltle: string = "";
  spaformTiltle: string = "";
  txtEmail: string = "";
  txtGender: string = "";
  txtFullName: string = "";
  txtPhoneNumber: string = "";
  fullName: string = "";
  apiMessage: string = "";
  txtPassword: string = "";
  txtConfirmPassword: string = "";
  txtBusinessLicense: string = "";
  txtCertification: string = "";
  txtWebsiteLink: string = "";
  txtBusinessLicenseDesc: string = "";
  txtBrowseFile: string = "";
  txtAddOther: string = "";
  description: string = "";
  star: string = "";
  txtCountry: string = "";
  txtCity: string = "";
  txtState: string = "";
  txtUploadDocuments: string = "";
  txtAddress: string = "";
  txtSpaName: string = "";
  txtSpaSumary: string = "";
  txtLocation: string = "";
  txtDocument: string = "";
  emailErrorMsg: string = "";
  typeErrorMsg: string = "";
  spaNameErrorMsg: string = "";
  spaSummaryErrorMsg: string = "";
  cityErrorMsg: string = "";
  stateErrorMsg: string = "";
  countryErrorMsg: string = "";
  addressErrorMsg: string = "";
  confirmPwdErrorMsg = "";
  loginTxt = "";
  nameErrorMsg = "";
  phoneNumberErrorMsg: string = "";
  accountText: string = "";
  currentCountryCode: any;
  // confirmPwdError: boolean;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      spaName: "",
      webLink: "",
      spaSummary: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "+91",
      phone: "",
      onlyPhone:"",
      emailError: false,
      spaNameError: false,
      spaSummaryError: false,
      pwdError: false,
      isSuccess: false,
      nameError: false,
      typeError: false,
      countryError: false,
      cityError: false,
      stateError: false,
      address: "",
      addressError: false,
      confirmPwdError: false,
      confirmPasswordError: false,
      fullName: '',
      apiMessage: '',
      genderType: 'Select',
      country: '',
      city: '',
      stateValue: '',
      phoneNumberError: false,
      confirmPassword: '',
      checked: false,
      pwdMsg: '',
      user_role: "Customer",
      open: false,
      avatarImage: null,
      imageUrl: null,
      anchorEl: null,
      upldAvatarLink: null,
      countries: Country.getAllCountries(),
      states: [],
      cities: [],
      countryCode: '',
      businessLicenseImage: [],
      certificateImage: [],
      otherImages: [],
      uploadersNumber: 2,
      addDisabled: false,
      businessLicenseError: '',
      certificateError: '',
      fileError: ''
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    this.textHeading = configJSON.textHeading;
    this.formTiltle = configJSON.formTiltle;
    this.spaformTiltle = configJSON.spaformTiltle;
    this.txtEmail = configJSON.txtEmail;
    this.txtGender = configJSON.txtGender;
    this.txtFullName = configJSON.txtFullName;
    this.txtPhoneNumber = configJSON.txtPhoneNumber
    this.txtPassword = configJSON.txtPassword;
    this.txtConfirmPassword = configJSON.txtConfirmPassword;
    this.description = configJSON.description;
    this.txtSpaName = configJSON.txtSpaName;
    this.txtSpaSumary = configJSON.txtSpaSumary;
    this.txtLocation = configJSON.txtLocation;
    this.txtDocument = configJSON.txtDocument;
    this.txtCountry = configJSON.txtCountry;
    this.txtCity = configJSON.txtCity;
    this.txtState = configJSON.txtState;
    this.txtUploadDocuments = configJSON.txtUploadDocuments;
    this.txtAddress = configJSON.txtAddress;
    this.txtBusinessLicense = configJSON.txtBusinessLicense;
    this.txtBusinessLicenseDesc = configJSON.txtBusinessLicenseDesc;
    this.txtCertification = configJSON.txtCertification;
    this.txtWebsiteLink = configJSON.txtWebsiteLink;
    this.txtBrowseFile = configJSON.txtBrowseFile;
    this.txtAddOther = configJSON.txtAddOther;
    this.star = configJSON.star;
    this.emailErrorMsg = configJSON.emailErrorMsg;
    this.typeErrorMsg = configJSON.typeErrorMsg;
    this.spaNameErrorMsg = configJSON.spaNameErrorMsg;
    this.spaSummaryErrorMsg = configJSON.spaSummaryErrorMsg;
    this.cityErrorMsg = configJSON.cityErrorMsg;
    this.stateErrorMsg = configJSON.stateErrorMsg;
    this.countryErrorMsg = configJSON.countryErrorMsg;
    this.addressErrorMsg = configJSON.addressErrorMsg;
    this.confirmPwdErrorMsg = configJSON.confirmPwdErrorMsg;
    this.loginTxt = configJSON.loginTxt;
    this.accountText = configJSON.accountText;
    this.nameErrorMsg = configJSON.nameErrorMsg;
    this.phoneNumberErrorMsg = configJSON.phoneNumberErrorMsg;

    this.getToken();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const navigationPayloadMessage = getName(MessageEnum.NavigationPayLoadMessage);

    if (message.id === navigationPayloadMessage) {
      const payload = message.getData(navigationPayloadMessage);

      const { password, confirmPassword, email, userRole, checked } = payload || {};
      this.setState({ user_role: userRole, email, password, confirmPassword, checked });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;
          this.handleResponse()
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          this.handleAPIResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.createSpaAccountApiCallId) {
          if (!responseJson.errors) {
            this.goToConfirmation();
          }
        }
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  goToTermsAndCondition = async () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
    let userData = {
      fullName: this.state.fullName,
      email: this.state.email,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      phone: this.state.phone,
      genderType: this.state.genderType,
      checked: this.state.checked
    }
    await setStorageData('userData', JSON.stringify(userData))

  }
  goToPrivacyPolicy = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  isStringNullOrBlank(txtStrng: string) {
    return !txtStrng || txtStrng.length === 0;
  }
  getValidations() {
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }


  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      return
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {

  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {

  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {

  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.txtPhoneNumberWebProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });
      this.txtInputLastNamePrpos.value = text;
    },
    value: ''
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });
      this.txtInputFirstNamePrpos.value = text;
    },
    value: ''
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };
  setEmail = (event: any) => {
    this.setState({
      email: event?.target.value, emailError: false
    });
  };
  setSpaName = (event: any) => {
    this.setState({
      spaName: event?.target?.value, spaNameError: false
    });
  };
  setWebLink = (event: any) => {
    this.setState({
      webLink: event?.target?.value,
    });

  }
  setSpaSummary = (event: any) => {
    this.setState({
      spaSummary: event?.target.value, spaSummaryError: false
    });
  };
  setPhoneNumber = (phoneNumber: string, newPhoneNumber:string, countryCode: number) => {
    this.setState({
      phone: phoneNumber,
      onlyPhone:newPhoneNumber,
      countryCodeSelected: countryCode.toString(),
      phoneNumberError: false,
    });
  };
  setConfirmPassword = (event: any) => {
    this.setState({
      confirmPassword: event?.target.value,
      confirmPasswordError: false
    });
  };
  setFullName = (event: any) => {
    this.setState({
      fullName: event?.target.value, nameError: false
    });
  };

  handlePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ open: !this.state.open });
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  setPassword = (event: any) => {
    this.setState({
      password: event?.target.value, pwdError: false,
    });
  };

  handleChange = (event: any) => {
    this.setState({ genderType: event?.target.value, typeError: false })
  }
  onSelectCountry = (event: any) => {
    const countryName = event.target.textContent;
    const countryCode = this.state.countries.find((country) => country.name === countryName)?.isoCode || '';
    const states = State.getStatesOfCountry(countryCode);
    this.setState({
      country: countryName,
      countryError: false,
      states,
      stateValue: '',
      city: '',
      countryCode
    });
  }

  onSelectState = (event: any) => {
    const stateName = event?.target.textContent;
    const stateObj = this.state.states.find((state) => state.name === stateName);
    const { countryCode, isoCode } = stateObj || { countryCode: '', isoCode: '' };

    const cities = City.getCitiesOfState(countryCode, isoCode);
    const allCountryCities = City.getCitiesOfCountry(this.state.countryCode);

    const resultCities = (cities.length && cities) || allCountryCities || [];

    this.setState({
      stateValue: event?.target.textContent,
      stateError: false,
      cities: resultCities,
      city: ''
    });
  }

  onSelectCity = (event: any) => {
    this.setState({ city: event?.target.textContent, cityError: false })
  }

  setAddress = (event: any) => {
    this.setState({ address: event?.target.value, addressError: false })

  }
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };
  handleClickShowConfirmPassword = () => {
    this.setState({ enableReTypePasswordField: !this.state.enableReTypePasswordField })
  }
  cleanFormData = () => this.setState(
    {
      user_role: '',
      email: '',
      fullName: '',
      password: '',
      confirmPassword: '',
      checked: false,
      phone: '',
      genderType: ''
    });

  handleUserRole = () => {
    this.cleanFormData();

    setStorageData('userRole', 'Spa');
    this.setState({
      user_role: 'Spa',
      emailError: false, pwdError: false, confirmPasswordError: false, checked: false
    });
  }
  gotoLogin = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  goToConfirmation = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpSPAConfirmation)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  setRememberMe = () => {
    this.setState({ checked: !this.state.checked })
  }

  validateFields = () => {
    const errors = {
      nameError: this.isStringNullOrBlank(this.state.fullName),
      emailError: this.isStringNullOrBlank(this.state.email),
      pwdError: this.isStringNullOrBlank(this.state.password),
      confirmPasswordError: this.isStringNullOrBlank(this.state.confirmPassword),
      phoneNumberError: this.isStringNullOrBlank(this.state.phone),
      typeError: this.state.genderType === 'Select',
    };

    this.setState(errors);

    return Object.values(errors).some(error => error);
  }

  setErrors = (errors: any) => {
    this.setState({
      nameError: errors.name,
      emailError: errors.email,
      pwdError: errors.password,
      confirmPasswordError: errors.confirmPassword,
      phoneNumberError: errors.phone,
      typeError: errors.type,
    });
  }

  validateField = () => {
    const errors = {
      name: this.isStringNullOrBlank(this.state.fullName),
      email: this.isStringNullOrBlank(this.state.email),
      password: this.isStringNullOrBlank(this.state.password),
      confirmPassword: this.isStringNullOrBlank(this.state.confirmPassword),
      phone: this.isStringNullOrBlank(this.state.phone),
      type: this.state.genderType === 'Select',
    };

    this.setErrors(errors);

    return Object.values(errors).some(error => error);
  }

  validateSpaFields = () => {

    const errors = {
      emailError: this.isStringNullOrBlank(this.state.email),
      pwdError: this.isStringNullOrBlank(this.state.password),
      confirmPasswordError: this.isStringNullOrBlank(this.state.confirmPassword),
    };

    this.setState(errors);
    return Object.values(errors).some(error => error);
  }

  createCustomerAccount = async () => {
    const { t } = getTranslationConfig();

    if (this.state.user_role == 'Customer' &&
      this.validateField()) {
      return false;
    }
    if (this.state.user_role === 'Spa' && this.validateSpaFields()) {
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.setState({ emailError: true })
      return false;
    }

    if (!((/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/).test(this.state.password))) {
      const pwdMsg = this.state.password.length < 8
        ? 'Weak password'
        : 'Password must contain at least 8 characters, including: \n\
          - One uppercase letter (A-Z) \n\
          - One lowercase letter (a-z) \n\
          - One digit (0-9)';
      this.setState({ pwdError: true, pwdMsg });
      return false;
    }

    if (this.state.password !== this.state.confirmPassword) {
      this.setState({ confirmPasswordError: true })
      return false;
    }
    if (!this.state.checked) {
      this.setState({ isSuccess: true, apiMessage: t['sign-form-terms-and-condition-error-message'] })
      return
    }
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };
    let attrs;
    const language = await getStorageData('language');

    attrs = {
      email: this.state.email,
      password: this.state.password,
      activated: this.state.checked,
      user_role: this.state.user_role,
      phone_number: this.state.onlyPhone,
      country_code: this.state.countryCodeSelected,
      ...(language && { language })
    };

    if (this.state.user_role == 'Customer') {

      attrs = {
        ...attrs,
        full_name: this.state.fullName,
        gender: this.state.genderType,
        full_phone_number: this.state.phone,
      }
    }
    const reqData = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: reqData,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updateToSpaOwnerAccount = async (formData: any) => {
    const authToken = await getStorageData('authToken');
    const headers = {
      "token": authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createSpaAccountApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsApaAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleAvatarUpload = (event: any) => {
    const avatarImage = event.target.files[0];
    this.setState({ avatarImage });
    const fileReader = new FileReader();
    fileReader.addEventListener("load", async () => {
      this.setState({ upldAvatarLink: fileReader.result });
    });
    fileReader.readAsDataURL(avatarImage);
  };

  getAllArrayItems = (complArray: File[][]) => complArray.length && complArray.reduce((acc, next) => ([...acc, ...next]));

  getFormDataFromPayload = (APIPayload: Record<string, any>) => {
    const formData = new FormData();

    for (let apiKey in APIPayload) {
      const entity = APIPayload[apiKey];
      if (Array.isArray(entity)) {
        entity.forEach((entityItem) => formData.append(apiKey, entityItem, entityItem.name))
      } else if (entity) {
        entity.name ? formData.append(apiKey, entity, entity.name) : formData.append(apiKey, String(entity));
      }
    }
    return formData
  }

  submit = async () => {
    const {
      avatarImage,
      spaName,
      spaSummary,
      country,
      stateValue,
      city,
      address,
      certificateImage,
      businessLicenseImage,
      otherImages,
      webLink
    } = this.state;
    const { t } = getTranslationConfig();

    const isValid = [
      spaName,
      spaSummary,
      country,
      stateValue,
      city,
      address,
      certificateImage.length,
      businessLicenseImage.length,
    ].every((formItem) => Boolean(formItem));

    if (isValid) {
      const APIPayload = {
        'account[profile_photo]': avatarImage,
        'account[profile][bio]': spaSummary,
        'account[profile][address]': address,
        'account[full_name]': spaName,
        'account[profile][city]': city,
        'account[profile][country]': country,
        'account[profile][postal_code]': stateValue,
        'account[profile][twitter_link]': webLink,
        'account[licenses][]': businessLicenseImage,
        'account[certificates][]': certificateImage,
        'account[other_files][]': this.getAllArrayItems(otherImages)
      };
      this.updateToSpaOwnerAccount(this.getFormDataFromPayload(APIPayload));
    } else {
      this.setState({
        spaNameError: !spaName,
        spaSummaryError: !spaSummary,
        countryError: !country,
        stateError: !stateValue,
        cityError: !city,
        addressError: !address,
        certificateError: certificateImage.length ? '' : t['spa-owner-registration-certifications-error'],
        businessLicenseError: businessLicenseImage.length ? '' : t['spa-owner-registration-business-license-error'],
        fileError: ''
      });
    }
  }

  goToSpaRegistration() {
    const message: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpSPAMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationPayLoadMessage), {
      email: this.state.email,
      userRole: this.state.user_role,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword
    });

    this.send(message);
  }

  goBack = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpSPAInitMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationPayLoadMessage), {
      email: this.state.email,
      userRole: this.state.user_role,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      checked: true
    });

    this.send(message);
  }

  handleResponse = () => {

    if (this.arrayholder && this.arrayholder.length !== 0) {
      let regexData = this.arrayholder[0];

      if (regexData.password_validation_regexp) {
        this.passwordReg = new RegExp(
          regexData.password_validation_regexp
        );
      }

      if (regexData.password_validation_rules) {
        this.setState({
          passwordHelperText: regexData.password_validation_rules
        });
      }

      if (regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  }

  handleAPIResponse = (responseJson: any) => {
    if (!responseJson.errors) {
      setStorageData('authToken', responseJson.meta.token);
      setStorageData('userRole', 'Customer');
      removeStorageData('userData')
      this.setState({ isSuccess: false, apiMessage: 'Account created successfully' })
      if (this.state.user_role == 'Spa') {
        this.goToSpaRegistration();
      } else {
        this.props.navigation.navigate('EmailAccountLoginBlock');
      }
    } else {
      //Check Error Response
      this.setState({ isSuccess: true, apiMessage: responseJson.errors[0].account })

    }
  }
  getUserRole = async () => {
    const userRole = await getStorageData('userRole');
    const userData = await getStorageData('userData')
    const terms = await getStorageData('termsandcondition')
    let storedData = JSON.parse(userData)
    storedData && this.setState(
      {
        user_role: userRole || this.state.user_role,
        email: storedData.email,
        fullName: storedData.fullName,
        password: storedData.password,
        confirmPassword: storedData.confirmPassword,
        checked: storedData.checked,
        phone: storedData.phone,
        genderType: storedData.genderType
      });
    if (terms) {
      this.setState({
        checked: terms === "false" ? false : true
      }, () => removeStorageData('terms'))
    }
  }
  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const message: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(message);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  onAddButtonClick() {
    this.setState({
      uploadersNumber: this.state.uploadersNumber + 1,
      addDisabled: true
    })
  }

  getStateOptions() {
    return this.state.states.length ? this.state.states.map((state) => state.name) : ['Default']
  }

  getCityOptions() {
    return this.state.cities.length ? this.state.cities.map((city) => city.name) : ['Default']
  }

  getPwdEyeIcon(condition: boolean) {
    return condition ? <Visibility /> : <VisibilityOff />
  }

  getLanguageBasedStyle<T>(isArabic: boolean, arabicValue: T, defaultValue: T): T {
    return isArabic ? arabicValue : defaultValue;
  }

  validatePhoneNumberExistence = (phone: string, country: CountryCode): boolean => {
    return !!phone && !!country;
  };
  
  parsePhoneNumberData = (phone: string, country: CountryCode) => {
    const phoneNumberWithPrefix = `+${phone}`;
    return parsePhoneNumber(phoneNumberWithPrefix, country);
  };
  
  validatePhoneNumberLogic = (phoneNumber: PhoneNumber, originalNumber: string, country: CountryCode) => {
    const parsedNumber = phoneNumber.nationalNumber.toString();
    const countryCode = getCountryCallingCode(country);
    const originalNumberWithoutCountryCode = originalNumber.replace(countryCode, '');
  
    return (
      phoneNumber.isValid() &&
      parsedNumber === originalNumberWithoutCountryCode
    );
  };
  
  validatePhoneNumber = (phone: string, country: CountryCode): boolean => {
    if (!this.validatePhoneNumberExistence(phone, country)) return false;
  
    try {
      const phoneNumber = this.parsePhoneNumberData(phone, country);
      const originalNumber = phone.replace(/\D/g, '');
      return this.validatePhoneNumberLogic(phoneNumber, originalNumber, country);
    } catch (error) {
      return false;
    }
  };

  handlePhoneChange = (value: any, countryData: any) => {
    if (countryData?.dialCode && countryData?.countryCode) {
      const country = countryData.countryCode.toUpperCase() as CountryCode;
      const countryCode = parseInt(countryData.dialCode, 10);
      try {
        let phoneNumber = value;
        if (value.startsWith('+' + countryCode)) {
          phoneNumber = value.replace('+' + countryCode, '');
        }

        const isValidPhone = this.validatePhoneNumber(phoneNumber, country);

        let newPhoneNumber = value.replace(/^\+/, '');
        if (newPhoneNumber.startsWith(countryCode.toString())) {
          newPhoneNumber = newPhoneNumber.substring(countryCode.toString().length);
        }

        newPhoneNumber = newPhoneNumber.replace(/\D/g, '');

        if (isValidPhone) {
          const parsedPhoneNumber = parsePhoneNumber(`+${countryCode}${phoneNumber}`, country);
          const nationalNumber = parsedPhoneNumber.nationalNumber.toString().replace(/^0+/, '');
    
          this.setPhoneNumber(nationalNumber, newPhoneNumber, countryCode);
        } else {
          this.setPhoneNumber('', '', countryData.dialCode);
          this.setState({ phoneNumberError: true });
        }
      } catch (error) {
        this.setPhoneNumber('', '', countryData.dialCode);
        this.setState({ phoneNumberError: true });
      }
    }
  };

  // Customizable Area End
}
