import React from "react";

import {
  Box,
  Button,
  Typography,
  CircularProgress
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styles, TypographyView } from './styledFont';
import { AppHeader } from '../../../components/src/AppHeader.web';
import { BreadCrumbs } from '../../../components/src/BreadCrumbs.web';
import { AppFooter } from '../../../components/src/AppFooter.web';
// Customizable Area End

import SubscriptionbillingController, { Props, SubscriptionTime, SubscriptionType } from "./SubscriptionbillingController";
import SubscriptionBlock from './components/SubscriptionBlock.web';
import { getStorageData } from "../../../framework/src/Utilities";
import { getTransContent, getTranslationConfig } from "../../../components/src/helpers";

export default class SubscriptionbillingPlans extends SubscriptionbillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  navigationMap = {
    'Home': 'LandingPage',
    'Spa Profile': 'SpaUserProfile',
    'My Subscription': 'Subscriptionbilling'
  }

  async componentDidMount() {
    this.getSubscriptionPlans();

    const userRoleData = await getStorageData('userRole');
    this.setState({ userRoleData: userRoleData });

  }
  // Customizable Area End

  render() {
    const subscriptionPlans = this.state.subscriptionPlans;    
    const { t, dir } = getTranslationConfig();

    const subscriptionTimeTranslations: Record<SubscriptionTime, string> = {
      [SubscriptionTime.year]: t['subscription-billing-plan-year'],
      [SubscriptionTime.month]: t['subscription-billing-plan-month'],
    };

    return (
      // Customizable Area Start
      <TypographyView>
        <Box style={styles.SafeAreaView} className='landing-venue-block'>
          <AppHeader context={this} forceUpdate={this.forceUpdate.bind(this)} />
          <Box className='search-location-block' pt={6} pb={10} px='140px' dir={dir}>
            {this.isSubscription() &&
              <BreadCrumbs
                items={[
                  t['subscription-billing-plan-breadcrumbs-home'],
                  t['subscription-billing-plan-breadcrumbs-profile'],
                  t['subscription-billing-plan-breadcrumbs-subscription'],
                  t['subscription-billing-plan-breadcrumbs-revise-plan'],
                ]}
                targetColor='#398378'
                navigationMap={this.navigationMap}
                navigate={this.props.navigation.navigate}
              />}
            <Typography
              style={{
                color: '#0F172A',
                fontFamily: 'Ubuntu',
                fontSize: '48px',
                fontWeight: 700,
                padding: '30px 0'
              }}
            >{t['subscription-billing-plan-heading-title']}</Typography>
            <Typography>
              {t['subscription-billing-plan-heading-description']}
            </Typography>
            {/* Plan modes buttons */}
            <Box display='flex' my={3} gridGap={14} justifyContent='space-between'>
              <Button
                data-test-id='monthly-btn'
                variant="contained"
                color="primary"
                style={{
                  ...styles.actionButton,
                  ...styles.modeButton,
                  ...this.getRenewButtonStyle(!this.state.annualMode),
                } as React.CSSProperties}
                onClick={() => this.setAnnualMode(false)}
              >
                {t['subscription-billing-monthly-plan']}
              </Button>
              <Button
                data-test-id='annual-btn'
                variant="contained"
                color="primary"
                style={{
                  ...styles.actionButton,
                  ...styles.modeButton,
                  ...this.getRenewButtonStyle(this.state.annualMode)
                } as React.CSSProperties}
                onClick={() => this.setAnnualMode(true)}
              >
                {t['subscription-billing-annual-plan']}
              </Button>
            </Box>
            {/* Plans Block */}
            <Box
              display='flex'
              gridGap={16}
              mt={6}
            >
              {subscriptionPlans.length ? subscriptionPlans.map((plan) => {
                const {
                  id,
                  name: title,
                  price,
                  plan_type: planType,
                  image_url: imageUrl,
                  parse_content: parsedDescrList,
                  
                } = plan;

                const { description } = getTransContent(plan);               

                const type = title.includes('Gold') ? 'Advanced' : 'Basic'
                const currentPeriod = this.state.annualMode ? SubscriptionTime.year : SubscriptionTime.month;

                const isAnnual = this.getLogicalAnd(this.state.annualMode, planType === SubscriptionType.annually);
                const isMonthly = this.getLogicalAnd(!this.state.annualMode, planType === SubscriptionType.monthly);

                return this.getLogicalOr(isAnnual, isMonthly) ?
                  <SubscriptionBlock
                    key={title}
                    navigation={this.props.navigation}
                    id=''
                    subsContent={{
                      title,
                      imageUrl,
                      currancy: this.currency,
                      price,
                      period: currentPeriod,
                      periodLabel: subscriptionTimeTranslations[currentPeriod],
                      description,
                      parsedDescrList
                    }}
                    subscriptionButtons={
                      this.state.userRoleData === 'Customer' ? undefined : (
                        <Button
                          data-test-id='buy-btn'
                          variant="contained"
                          color="primary"
                          style={{
                            ...styles.actionButton,
                            background: '#398378',
                            color: '#FFFFFF',
                            padding: '8px 32px'
                          } as React.CSSProperties}
                          onClick={() => this.gotoSubscriptionPayment(id, title, type)}
                        >
                          {t['subscription-billing-buy-button']}
                        </Button>
                      )
                    }
                  /> : null
              }) :
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: '42vh',
                  width: '100vw'
                }}>
                  <CircularProgress style={{ color: '#398378' }} />
                </Box>
              }
            </Box>
          </Box>
          {/* footer */}
          <AppFooter navigation={this.props.navigation} />
        </Box>
      </TypographyView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
